var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileSizeValidation } from '../uploads/validation/validations/file-size-validation';
import { UploadValidator } from '../uploads/validation/upload-validator';
import { convertToBytes } from '../core/utils/convertToBytes';
import { FileTypeValidation } from '../uploads/validation/validations/file-type-validation';
import * as i0 from "@angular/core";
import * as i1 from "../core/config/settings.service";
import * as i2 from "../core/ui/toast.service";
import * as i3 from "../core/translations/translations.service";
var AvatarValidator = /** @class */ (function (_super) {
    __extends(AvatarValidator, _super);
    function AvatarValidator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DEFAULT_MAX_SIZE_MB = 5;
        return _this;
    }
    AvatarValidator.prototype.initValidations = function () {
        var validations = [
            new FileSizeValidation({ maxSize: convertToBytes(this.DEFAULT_MAX_SIZE_MB, 'MB') }, this.i18n),
            new FileTypeValidation({ types: ['image'] }, this.i18n),
        ];
        (_a = this.validations).push.apply(_a, validations);
        var _a;
    };
    AvatarValidator.ngInjectableDef = i0.defineInjectable({ factory: function AvatarValidator_Factory() { return new AvatarValidator(i0.inject(i1.Settings), i0.inject(i2.Toast), i0.inject(i3.Translations)); }, token: AvatarValidator, providedIn: "root" });
    return AvatarValidator;
}(UploadValidator));
export { AvatarValidator };
