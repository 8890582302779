<section class="scroll-container" id="article-host">
    <hc-compact-header [category]="article.categories[0].parent || article.categories[0]">
        <breadcrumbs [resource]="article" resourceType="article"></breadcrumbs>
    </hc-compact-header>

    <div class="hc-content">
        <div class="left">
            <hc-sidenav [category]="article.categories[0].parent"></hc-sidenav>
        </div>
        <div class="center">
            <article></article>
        </div>
    </div>

    <customer-footer></customer-footer>
</section>