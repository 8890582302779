/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./update-article-links.directive";
import * as i3 from "@angular/router";
import * as i4 from "../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../common/core/translations/translations.service";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "@angular/common";
import * as i8 from "../article-feedback/article-feedback.component.ngfactory";
import * as i9 from "../article-feedback/article-feedback.component";
import * as i10 from "../help-center.service";
import * as i11 from "./article.component";
import * as i12 from "@angular/platform-browser";
var styles_ArticleComponent = [i0.styles];
var RenderType_ArticleComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArticleComponent, data: {} });
export { RenderType_ArticleComponent as RenderType_ArticleComponent };
function View_ArticleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "section", [["class", "article-container"], ["id", "article"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "article-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "article-body"], ["updateArticleLinks", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(6, 4210688, null, 0, i2.UpdateArticleLinksDirective, [i1.ElementRef, i3.Router], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "submit-ticket-container container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(11, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Have more questions?"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["routerLink", "/help-center/tickets/new"], ["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(16, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Submit a Request"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "article-feedback", [], null, null, null, i8.View_ArticleFeedbackComponent_0, i8.RenderType_ArticleFeedbackComponent)), i1.ɵdid(21, 49152, null, 0, i9.ArticleFeedbackComponent, [i10.HelpCenterService], { articleId: [0, "articleId"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "/help-center/tickets/new"; _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.article.id; _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.trustedArticleBody; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 15).target; var currVal_3 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_2, currVal_3); }); }
export function View_ArticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "article", [], null, null, null, View_ArticleComponent_0, RenderType_ArticleComponent)), i1.ɵdid(1, 8503296, null, 0, i11.ArticleComponent, [i12.DomSanitizer, i3.ActivatedRoute, i3.Router, i10.HelpCenterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleComponentNgFactory = i1.ɵccf("article", i11.ArticleComponent, View_ArticleComponent_Host_0, { article: "article" }, {}, []);
export { ArticleComponentNgFactory as ArticleComponentNgFactory };
