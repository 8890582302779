/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../default-preview/default-preview.component.ngfactory";
import * as i3 from "../default-preview/default-preview.component";
import * as i4 from "../current-preview-file";
import * as i5 from "../preview-url-transformer";
import * as i6 from "../../core/config/settings.service";
import * as i7 from "../../core/http/app-http-client.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./audio-preview.component";
var styles_AudioPreviewComponent = [i0.styles];
var RenderType_AudioPreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AudioPreviewComponent, data: {} });
export { RenderType_AudioPreviewComponent as RenderType_AudioPreviewComponent };
function View_AudioPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "default-preview", [], null, null, null, i2.View_DefaultPreviewComponent_0, i2.RenderType_DefaultPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i3.DefaultPreviewComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer], null, null)], null, null); }
export function View_AudioPreviewComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { playerEl: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["playerEl", 1]], null, 0, "audio", [["class", "preview-object"], ["controls", "controls"]], [[2, "hidden", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AudioPreviewComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.invalidMedia; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invalidMedia; _ck(_v, 1, 0, currVal_0); }); }
export function View_AudioPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "audio-preview", [], null, null, null, View_AudioPreviewComponent_0, RenderType_AudioPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i10.AudioPreviewComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioPreviewComponentNgFactory = i1.ɵccf("audio-preview", i10.AudioPreviewComponent, View_AudioPreviewComponent_Host_0, {}, {}, []);
export { AudioPreviewComponentNgFactory as AudioPreviewComponentNgFactory };
