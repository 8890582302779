import { Routes } from '@angular/router';
import { HelpCenterComponent } from './help-center.component';
import { ArticleResolve } from '../shared/article/article-resolve.service';
import { HelpCenterResolve } from './help-center-resolve.service';
import { ArticleHostComponent } from './article-host/article-host.component';
import { CategoryResolve } from './category/category-resolve.service';
import { CategoryComponent } from './category/category.component';
import { HcSearchPageComponent } from './hc-search-page/hc-search-page.component';
import { HcSearchPageResolve } from './hc-search-page/hc-search-page-resolve.service';
import { CheckPermissionsGuard } from '../../../common/guards/check-permissions-guard.service';
var ɵ0 = { permissions: ['categories.view'] }, ɵ1 = { permissions: ['categories.view', 'articles.view'] }, ɵ2 = { permissions: ['categories.view', 'articles.view'] }, ɵ3 = { permissions: ['categories.view', 'articles.view'] }, ɵ4 = { permissions: ['categories.view', 'articles.view'] }, ɵ5 = { permissions: ['articles.view'] };
var routes = [
    { path: 'help-center', canActivateChild: [CheckPermissionsGuard], children: [
            {
                path: '',
                component: HelpCenterComponent,
                resolve: { categories: HelpCenterResolve },
                data: ɵ0
            },
            {
                path: 'articles/:articleId/:slug',
                component: ArticleHostComponent,
                resolve: { article: ArticleResolve },
                data: ɵ1
            },
            {
                path: 'articles/:parentId/:articleId/:slug',
                component: ArticleHostComponent,
                resolve: { article: ArticleResolve },
                data: ɵ2
            },
            {
                path: 'articles/:parentId/:childId/:articleId/:slug',
                component: ArticleHostComponent,
                resolve: { article: ArticleResolve },
                data: ɵ3
            },
            {
                path: 'categories/:categoryId/:slug',
                component: CategoryComponent,
                resolve: { resolves: CategoryResolve },
                data: ɵ4
            },
            {
                path: 'search/:query',
                component: HcSearchPageComponent,
                resolve: { results: HcSearchPageResolve },
                data: ɵ5
            },
            {
                path: 'tickets',
                loadChildren: 'app/customer-mailbox/customer-mailbox.module#CustomerMailboxModule'
            },
            {
                path: 'manage',
                loadChildren: 'app/help-center/manage/help-center-manage.module#HcManageModule'
            },
        ] },
];
var HelpCenterRoutingModule = /** @class */ (function () {
    function HelpCenterRoutingModule() {
    }
    return HelpCenterRoutingModule;
}());
export { HelpCenterRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
