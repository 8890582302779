import {Injectable} from '@angular/core';
import {Article} from './models/Article';
import {Category} from './models/Category';
import {TitleService} from '../../common/core/services/title.service';

@Injectable({
    providedIn: 'root'
})
export class HcTitleService extends TitleService {
    protected separator = ' - ';

    /**
     * Get help center home title.
     */
    public getHomeTitle() {
        return this.i18n.t('Help Center') + this.separator + this.settings.get('branding.site_name');
    }

    /**
     * Set help center home title.
     */
    public setHomeTitle() {
        const title = this.i18n.t('Help Center') + this.separator + this.settings.get('branding.site_name');
        this.title.setTitle(title);
    }

    /**
     * Set help center article title.
     */
    public setArticleTitle(article: Article) {
        this.title.setTitle(article.title + this.separator + this.getHomeTitle());
    }

    /**
     * Set help center category title.
     */
    public setCategoryTitle(category: Category) {
        this.title.setTitle(category.name + this.separator + this.getHomeTitle());
    }

    /**
     * Set help center search title.
     */
    public setSearchTitle(query: string) {
        this.title.setTitle(this.i18n.t('Search Results') + this.separator + this.getHomeTitle());
    }
}
