import {Injectable} from '@angular/core';
import {Article} from '../../shared/models/Article';
import {BackendResponse} from '../../../common/core/types/backend-response';
import {HttpCacheClient} from '../../../common/core/http/http-cache-client';

@Injectable()
export class HelpCenterService {
    constructor(public httpClient: HttpCacheClient) {}

    /**
     * Search help center articles.
     */
    public findArticles(query, params: Object = {}): BackendResponse<{data: Article[]}> {
        return this.httpClient.get('search/articles/' + encodeURIComponent(query), params);
    }

    /**
     * Create new help center article.
     */
    public createArticle(payload): BackendResponse<{data: Article}> {
        return this.httpClient.post('help-center/articles', payload);
    }

    /**
     * Update existing help center article.
     */
    public updateArticle(payload): BackendResponse<{data: Article}> {
        return this.httpClient.put('help-center/articles/' + payload.id, payload);
    }

    /**
     * Submit user feedback about specified article.
     */
    public submitArticleFeedback(id: number, payload: Object) {
        return this.httpClient.post('help-center/articles/' + id + '/feedback', payload);
    }

    /**
     * Fetch help center articles.
     */
    public getArticles(params = null): BackendResponse<{data: Article[]}> {
        return this.httpClient.get('help-center/articles', params);
    }

    /**
     * Get a single help center article matching given id.
     */
    public getArticle(id: number, params?): BackendResponse<{data: Article}> {
        return this.httpClient.get('help-center/articles/' + id, params);
    }

    /**
     * Get categories, child categories and articles for help center front page.
     */
    public getDataForHelpCenterFrontPage() {
        return this.httpClient.getWithCache('help-center');
    }

    /**
     * Get categories, child categories and articles for help center sidenav.
     */
    public getDataForHelpCenterSidenav() {
        return this.httpClient.getWithCache('help-center/sidenav');
    }

    /**
     * Delete articles with given ids.
     */
    public deleteArticles(articleIds: number[]) {
        return this.httpClient.delete('help-center/articles', {ids: articleIds});
    }
}
