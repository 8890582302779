/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./text-preview.component";
import * as i4 from "../current-preview-file";
import * as i5 from "../preview-url-transformer";
import * as i6 from "../../core/config/settings.service";
import * as i7 from "../../core/http/app-http-client.service";
import * as i8 from "@angular/platform-browser";
var styles_TextPreviewComponent = [i0.styles];
var RenderType_TextPreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TextPreviewComponent, data: {} });
export { RenderType_TextPreviewComponent as RenderType_TextPreviewComponent };
export function View_TextPreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.content)); _ck(_v, 1, 0, currVal_0); }); }
export function View_TextPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "text-preview", [], [[8, "className", 0]], null, null, View_TextPreviewComponent_0, RenderType_TextPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i3.TextPreviewComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).className; _ck(_v, 0, 0, currVal_0); }); }
var TextPreviewComponentNgFactory = i1.ɵccf("text-preview", i3.TextPreviewComponent, View_TextPreviewComponent_Host_0, {}, {}, []);
export { TextPreviewComponentNgFactory as TextPreviewComponentNgFactory };
