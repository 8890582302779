/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hc-sidenav.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../common/core/ui/custom-scrollbar/custom-scrollbar.directive";
import * as i5 from "../../../../common/core/ui/breakpoints.service";
import * as i6 from "@angular/cdk/observers";
import * as i7 from "../../../../common/core/config/settings.service";
import * as i8 from "../../../../common/core/ui/custom-scrollbar/supports-native-scrollbar-styling";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./hc-sidenav.component";
import * as i12 from "../../shared/help-center.service";
import * as i13 from "../../shared/hc-urls.service";
var styles_HcSidenavComponent = [i0.styles];
var RenderType_HcSidenavComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HcSidenavComponent, data: {} });
export { RenderType_HcSidenavComponent as RenderType_HcSidenavComponent };
function View_HcSidenavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [], [[2, "active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n                "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.urls.getArticleLink(_v.context.$implicit); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routeIsActive("article", _v.context.$implicit.id); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_4); }); }
function View_HcSidenavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], [[2, "active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HcSidenavComponent_3)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.urls.getCategoryLink(_v.context.$implicit); _ck(_v, 4, 0, currVal_3); var currVal_5 = _v.context.$implicit.articles; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routeIsActive("category", _v.context.$implicit.id); var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_4); }); }
function View_HcSidenavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "sidenav-content scroll-container"], ["customScrollbar", "lite"]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i4.CustomScrollbarDirective, [i1.ElementRef, i1.Renderer2, i5.BreakpointsService, i1.NgZone, i6.MutationObserverFactory, i7.Settings, i8.SUPPORTS_NATIVE_SCROLLBAR_STYLING], null, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HcSidenavComponent_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "mobile-toggle no-style"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidenav() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "menu"]], [[2, "mat-icon-inline", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(11, 638976, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navData.children; _ck(_v, 6, 0, currVal_0); var currVal_2 = "menu"; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_1); }); }
export function View_HcSidenavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HcSidenavComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.navData && _co.navData.children); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HcSidenavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hc-sidenav", [], null, null, null, View_HcSidenavComponent_0, RenderType_HcSidenavComponent)), i1.ɵdid(1, 114688, null, 0, i11.HcSidenavComponent, [i12.HelpCenterService, i13.HcUrls, i1.ElementRef, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HcSidenavComponentNgFactory = i1.ɵccf("hc-sidenav", i11.HcSidenavComponent, View_HcSidenavComponent_Host_0, { category: "category" }, {}, []);
export { HcSidenavComponentNgFactory as HcSidenavComponentNgFactory };
