<section class="scroll-container" id="category-component">
    <hc-compact-header [category]="category.parent || category">
        <breadcrumbs [resource]="category" resourceType="category"></breadcrumbs>
    </hc-compact-header>

    <div class="hc-content">
        <div class="left">
            <hc-sidenav [category]="category.parent || category"></hc-sidenav>
        </div>

        <div class="center">
            <div class="category-articles">
                <div class="header">
                    <div class="title">
                        <h1 class="text">{{category.name}}</h1>
                        <articles-order-select (onChange)="reloadArticles($event)"></articles-order-select>
                    </div>
                </div>

                <div class="articles">
                    <a class="article" *ngFor="let article of articles" [routerLink]="urls.getArticleLink(article)">
                        <mat-icon svgIcon="description"></mat-icon>
                        <div class="description">
                            <div class="title">{{article.title}}</div>
                            <div class="body">{{article.body}}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <customer-footer></customer-footer>
</section>