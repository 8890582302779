import {Component, ElementRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {HelpCenterService} from '../../shared/help-center.service';
import {Category} from '../../../shared/models/Category';
import {HcUrls} from '../../shared/hc-urls.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'hc-sidenav',
    templateUrl: './hc-sidenav.component.html',
    styleUrls: ['./hc-sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HcSidenavComponent implements OnInit {

    @Input() category: Category;

    public navData: Category;

    constructor(
        private helpCenter: HelpCenterService,
        public urls: HcUrls,
        private el: ElementRef,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.fetchCategories();
    }

    private fetchCategories() {
        this.helpCenter.getDataForHelpCenterSidenav().subscribe(response => {
            this.navData = response.data.find(cat => cat.id === this.category.id);
        });
    }

    public toggleSidenav() {
        const el = this.el.nativeElement as HTMLElement;
        el.parentElement.classList.toggle('mobile-visible');
    }

    public routeIsActive(type: 'category'|'article', id: number): boolean {
        const params = this.route.snapshot.params;

        if (type === 'category') {
            // don't highlight both article and category titles
            return ! params.articleId && params.childId === id;
        } else {
            return params.articleId === id;
        }
    }
}
