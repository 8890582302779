import {Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import {FormControl} from '@angular/forms';
import {HelpCenterService} from '../../shared/help-center.service';
import {HcUrls} from '../../shared/hc-urls.service';
import {Article} from '../../../shared/models/Article';
import {Router} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Category} from '../../../shared/models/Category';
import {of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';

@Component({
    selector: 'suggested-articles-dropdown',
    templateUrl: './suggested-articles-dropdown.component.html',
    styleUrls: ['./suggested-articles-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuggestedArticlesDropdownComponent implements OnInit {
    @ViewChild('input') searchInput: ElementRef;

    @Input() public category: Category;
    @Input() public placeholder: string;
    @Input() public openInNewPage: boolean;

    public searchQuery = new FormControl();
    public articles: Article[] = [];
    public searching = false;
    public haveSearched = false;
    public autocompleteWidth: number;

    constructor(
        private helpCenter: HelpCenterService,
        public urls: HcUrls,
        private router: Router,
        private sanitizer: DomSanitizer,
    ) {}
    
    public ngOnInit() {
        this.bindToSearchQueryControl();
    }

    public viewAllResults() {
        if ( ! this.searchQuery.value) return;
        this.router.navigate(this.urls.getSearchPageLink(this.searchQuery.value));
    }

    public resetSearchQuery() {
        this.searchQuery.reset();
    }

    public trustArticleBody(body: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(body);
    }

    public getWidth(): number {
        if (this.autocompleteWidth) return this.autocompleteWidth;
        return this.autocompleteWidth = this.searchInput.nativeElement.offsetWidth + 40;
    }

    private searchArticles(query: string) {
        if ( ! query) return of({data: []});

        this.searching = true;
        const params = {category: this.category ? this.category.name : null};

        return this.helpCenter.findArticles(query, params);
    }

    private bindToSearchQueryControl() {
        this.searchQuery.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                switchMap(query => this.searchArticles(query)),
                catchError(() => of({data: []})),
            ).subscribe(response => {
                this.setArticles(response.data);
                this.haveSearched = true;
                this.searching = false;
            });
    }

    private setArticles(articles: Article[]) {
        if ( ! articles) articles = [];
        this.articles = articles;
    }
}
