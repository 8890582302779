import { ElementRef, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BreakpointsService } from '../breakpoints.service';
import { OverlayPanelRef } from './overlay-panel-ref';
import { OVERLAY_PANEL_DATA } from './overlay-panel-data';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "../breakpoints.service";
var DEFAULT_CONFIG = {
    hasBackdrop: true,
    closeOnBackdropClick: true,
    panelClass: 'overlay-panel',
};
var OverlayPanel = /** @class */ (function () {
    function OverlayPanel(overlay, breakpoints, injector) {
        this.overlay = overlay;
        this.breakpoints = breakpoints;
        this.injector = injector;
    }
    OverlayPanel.prototype.open = function (component, userConfig) {
        var config = Object.assign({}, DEFAULT_CONFIG, userConfig);
        var overlayRef = this.overlay.create({
            positionStrategy: this.getPositionStrategy(config),
            hasBackdrop: config.hasBackdrop,
            panelClass: config.panelClass,
        });
        var overlayPanelRef = new OverlayPanelRef(overlayRef);
        var portal = new ComponentPortal(component, null, this.createInjector(config, overlayPanelRef));
        overlayRef.attach(portal);
        if (config.closeOnBackdropClick) {
            overlayRef.backdropClick().subscribe(function () { return overlayPanelRef.close(); });
        }
        return overlayPanelRef;
    };
    OverlayPanel.prototype.createInjector = function (config, dialogRef) {
        var injectionTokens = new WeakMap();
        injectionTokens.set(OverlayPanelRef, dialogRef);
        injectionTokens.set(OVERLAY_PANEL_DATA, config.data || null);
        return new PortalInjector(this.injector, injectionTokens);
    };
    OverlayPanel.prototype.getPositionStrategy = function (config) {
        if (config.positionStrategy) {
            return config.positionStrategy;
        }
        else if (config.origin === 'global') {
            return this.getGlobalPositionStrategy(config);
        }
        else {
            return this.getConnectedPositionStrategy(config);
        }
    };
    /**
     * Get position strategy for overlay positioned globally.
     */
    OverlayPanel.prototype.getGlobalPositionStrategy = function (config) {
        var position = this.getPositionOption(config);
        if (position === 'center') {
            return this.overlay.position().global().centerHorizontally().centerVertically();
        }
        else {
            var global_1 = this.overlay.position().global();
            Object.keys(position).forEach(function (key) {
                global_1[key](position[key]);
            });
            return global_1;
        }
    };
    /**
     * Get position strategy for overlay connected to origin element.
     */
    OverlayPanel.prototype.getConnectedPositionStrategy = function (config) {
        return this.overlay.position()
            .flexibleConnectedTo(config.origin)
            .withPositions(this.getPositionOption(config));
    };
    /**
     * Select position strategy based on current device.
     */
    OverlayPanel.prototype.getPositionOption = function (config) {
        if (config.mobilePosition && this.breakpoints.isMobile) {
            return config.mobilePosition;
        }
        else {
            return config.position;
        }
    };
    OverlayPanel.ngInjectableDef = i0.defineInjectable({ factory: function OverlayPanel_Factory() { return new OverlayPanel(i0.inject(i1.Overlay), i0.inject(i2.BreakpointsService), i0.inject(i0.INJECTOR)); }, token: OverlayPanel, providedIn: "root" });
    return OverlayPanel;
}());
export { OverlayPanel };
