<form class="suggestions-container" id="suggested-articles-dropdown" [class.has-results]="articles.length" [class.searching]="searching" (ngSubmit)="viewAllResults()">
    <div class="input-container" [class.dropdown-open]="auto.isOpen">
        <input type="text" class="search-input" [attr.placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="searchQuery" #input>
        <mat-icon svgIcon="search" class="search-icon input-icon"></mat-icon>
    </div>
</form>

<mat-autocomplete #auto="matAutocomplete" class="search-results" [panelWidth]="getWidth()" customScrollbar>
    <mat-option class="result dropdown-item" #menuItem *ngFor="let article of articles" [routerLink]="urls.getArticleLink(article)" (click)="resetSearchQuery()">
        <mat-icon svgIcon="description"></mat-icon>
        <div class="result-content">
            <div class="title">{{article.title}}</div>
            <div class="body" [innerHTML]="trustArticleBody(article.body)"></div>
            <div class="meta" *ngIf="article.categories && article.categories[0]">
                            <span class="category inline" *ngIf="article.categories[0].parent">
                                {{article.categories[0].parent.name}}
                            </span>
                <span class="child-category inline" *ngIf="article.categories[0]">{{article.categories[0].name}}</span>
            </div>
        </div>
    </mat-option>

    <mat-option class="no-results-option" [disabled]="true" *ngIf="!articles?.length && haveSearched && !searching">
        <no-results-message>
            <span primary-text trans>Sorry, could not find any results for that query.</span>
            <span secondary-text trans>Try using different search term or browsing categories manually.</span>
        </no-results-message>
    </mat-option>

    <mat-option class="see-all" [routerLink]="urls.getSearchPageLink(searchQuery.value)" *ngIf="!searching && articles?.length" trans>See all results...</mat-option>
</mat-autocomplete>