import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Category} from '../../../shared/models/Category';
import {Settings} from '../../../../common/core/config/settings.service';

@Component({
    selector: 'hc-compact-header',
    templateUrl: './hc-compact-header.component.html',
    styleUrls: ['./hc-compact-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {'id': 'hc-compact-header'},
})

export class HcCompactHeaderComponent {
    @Input() public category: Category;

    constructor(public settings: Settings) {}
}
