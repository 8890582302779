/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article-modal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "../article/article.component.ngfactory";
import * as i11 from "../article/article.component";
import * as i12 from "@angular/platform-browser";
import * as i13 from "@angular/router";
import * as i14 from "../help-center.service";
import * as i15 from "./article-modal.component";
var styles_ArticleModalComponent = [i0.styles];
var RenderType_ArticleModalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArticleModalComponent, data: {} });
export { RenderType_ArticleModalComponent as RenderType_ArticleModalComponent };
export function View_ArticleModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["class", "close-button"], ["mat-icon-button", ""], ["tabindex", "-1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(7, 638976, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "article", [], null, null, null, i10.View_ArticleComponent_0, i10.RenderType_ArticleComponent)), i1.ɵdid(11, 8503296, null, 0, i11.ArticleComponent, [i12.DomSanitizer, i13.ActivatedRoute, i13.Router, i14.HelpCenterService], { article: [0, "article"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "close"; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.article; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 7).inline; _ck(_v, 6, 0, currVal_2); }); }
export function View_ArticleModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "article-modal", [], null, null, null, View_ArticleModalComponent_0, RenderType_ArticleModalComponent)), i1.ɵdid(1, 49152, null, 0, i15.ArticleModalComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ArticleModalComponentNgFactory = i1.ɵccf("article-modal", i15.ArticleModalComponent, View_ArticleModalComponent_Host_0, {}, {}, []);
export { ArticleModalComponentNgFactory as ArticleModalComponentNgFactory };
