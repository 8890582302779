import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Translations } from '../translations/translations.service';
import { Settings } from '../config/settings.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../translations/translations.service";
import * as i4 from "../config/settings.service";
var TitleService = /** @class */ (function () {
    function TitleService(router, title, i18n, settings) {
        this.router = router;
        this.title = title;
        this.i18n = i18n;
        this.settings = settings;
    }
    TitleService.prototype.init = function () {
        this.bindToRouterEvents();
    };
    TitleService.prototype.getTitle = function (data) {
        switch (data.name) {
            case 'account-settings':
                return this.i18n.t('Account Settings');
            case 'user':
                return data.user.display_name;
            case 'search':
                return this.i18n.t('Search');
            default:
                return this.getDefaultTitle();
        }
    };
    TitleService.prototype.getDefaultTitle = function () {
        return this.settings.get('branding.site_name');
    };
    TitleService.prototype.bindToRouterEvents = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }), map(function () {
            var route = _this.router.routerState.root;
            while (route.firstChild)
                route = route.firstChild;
            return route;
        }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; }))
            .subscribe(function (data) {
            _this.routeData = data;
            _this.title.setTitle(_this.getTitle(data));
        });
    };
    TitleService.ngInjectableDef = i0.defineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.inject(i1.Router), i0.inject(i2.Title), i0.inject(i3.Translations), i0.inject(i4.Settings)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
