/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./default-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./default-preview.component";
import * as i8 from "../current-preview-file";
import * as i9 from "../preview-url-transformer";
import * as i10 from "../../core/config/settings.service";
import * as i11 from "../../core/http/app-http-client.service";
import * as i12 from "@angular/platform-browser";
var styles_DefaultPreviewComponent = [i0.styles];
var RenderType_DefaultPreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DefaultPreviewComponent, data: {} });
export { RenderType_DefaultPreviewComponent as RenderType_DefaultPreviewComponent };
export function View_DefaultPreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "panel preview-object"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "message"], ["trans", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No file preview available."])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Download"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).disabled || null); var currVal_1 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_DefaultPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "default-preview", [], null, null, null, View_DefaultPreviewComponent_0, RenderType_DefaultPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i7.DefaultPreviewComponent, [i8.CURRENT_PREVIEW_FILE, i9.PREVIEW_URL_TRANSFORMER, i10.Settings, i11.AppHttpClient, i12.DomSanitizer], null, null)], null, null); }
var DefaultPreviewComponentNgFactory = i1.ɵccf("default-preview", i7.DefaultPreviewComponent, View_DefaultPreviewComponent_Host_0, {}, {}, []);
export { DefaultPreviewComponentNgFactory as DefaultPreviewComponentNgFactory };
