var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { Paginator } from './paginator.service';
import { delay } from 'rxjs/operators';
import { snakeCase } from '../../core/utils/snake-case';
import * as i0 from "@angular/core";
var UrlAwarePaginator = /** @class */ (function (_super) {
    __extends(UrlAwarePaginator, _super);
    /**
     * UrlAwarePaginator Constructor.
     */
    function UrlAwarePaginator(injector) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        /**
         * Observable used to refresh paginator manually
         * when route params or query params have not changed.
         */
        _this.onRefresh = new BehaviorSubject({});
        _this.router = _this.injector.get(Router);
        return _this;
    }
    /**
     * Start pagination.
     */
    UrlAwarePaginator.prototype.paginate = function (url, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.destroy();
        this.serverUri = url;
        // we need to delay query params observable slightly so that
        // route params are fired before query params which gives
        // components a chance to destroy paginator and avoid
        // double requests if query and route params change at the same time
        return new Observable(function (observer) {
            _this.subscription = combineLatest(_this.router.routerState.root.queryParams.pipe(delay(10)), _this.onRefresh, function (params1, params2) { return Object.assign({}, options, params1, params2); }).subscribe(function (merged) {
                _this.makeRequest(merged).subscribe(function (response) { return observer.next(response); });
            });
        });
    };
    /**
     * Refresh paginator with specified params.
     */
    UrlAwarePaginator.prototype.refresh = function (params) {
        if (params === void 0) { params = {}; }
        this.onRefresh.next(params);
        return this.serverRequest;
    };
    /**
     * Go to specified page.
     */
    UrlAwarePaginator.prototype.goToPage = function (page) {
        this.updateQueryParams({ page: page });
    };
    /**
     * Fired when any of router parameters are changed by user (via pagination controls).
     */
    UrlAwarePaginator.prototype.onParamChange = function (name) {
        var params = {};
        params[snakeCase(name)] = this.params[name];
        this.updateQueryParams(params);
    };
    /**
     * Update query params of currently active url.
     */
    UrlAwarePaginator.prototype.updateQueryParams = function (params) {
        if (params === void 0) { params = {}; }
        var merged = Object.assign({}, this.router.routerState.snapshot.root.queryParams, params);
        this.router.navigate([], { queryParams: this.normalizeParams(merged) });
    };
    /**
     * Remove all observables and unsubscribe from route params.
     */
    UrlAwarePaginator.prototype.destroy = function () {
        this.subscription && this.subscription.unsubscribe();
        _super.prototype.destroy.call(this);
    };
    UrlAwarePaginator.ngInjectableDef = i0.defineInjectable({ factory: function UrlAwarePaginator_Factory() { return new UrlAwarePaginator(i0.inject(i0.INJECTOR)); }, token: UrlAwarePaginator, providedIn: "root" });
    return UrlAwarePaginator;
}(Paginator));
export { UrlAwarePaginator };
