import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Article} from '../../../shared/models/Article';
import {HcUrls} from '../../shared/hc-urls.service';
import {HcTitleService} from '../../../shared/hc-title.service';

@Component({
    selector: 'article-host',
    templateUrl: './article-host.component.html',
    styleUrls: ['./article-host.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ArticleHostComponent implements OnInit {
    public article: Article;

    constructor(
        private route: ActivatedRoute,
        public urls: HcUrls,
        private title: HcTitleService,
    ) {}

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.article = data['article'];
            this.title.setArticleTitle(this.article);
        });
    }
}
