/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hc-compact-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/ui/material-navbar/material-navbar.component.ngfactory";
import * as i3 from "../../../../common/core/ui/material-navbar/material-navbar.component";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../../../common/auth/current-user";
import * as i6 from "../suggested-articles-dropdown/suggested-articles-dropdown.component.ngfactory";
import * as i7 from "../suggested-articles-dropdown/suggested-articles-dropdown.component";
import * as i8 from "../../shared/help-center.service";
import * as i9 from "../../shared/hc-urls.service";
import * as i10 from "@angular/router";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./hc-compact-header.component";
var styles_HcCompactHeaderComponent = [i0.styles];
var RenderType_HcCompactHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HcCompactHeaderComponent, data: {} });
export { RenderType_HcCompactHeaderComponent as RenderType_HcCompactHeaderComponent };
export function View_HcCompactHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "material-navbar", [["menuPosition", "header"]], null, null, null, i2.View_MaterialNavbar_0, i2.RenderType_MaterialNavbar)), i1.ɵdid(1, 49152, null, 0, i3.MaterialNavbar, [i4.Settings, i5.CurrentUser], { menuPosition: [0, "menuPosition"], container: [1, "container"], showAuthButtons: [2, "showAuthButtons"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "search-bar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "suggested-articles-dropdown", [], null, null, null, i6.View_SuggestedArticlesDropdownComponent_0, i6.RenderType_SuggestedArticlesDropdownComponent)), i1.ɵdid(12, 114688, null, 0, i7.SuggestedArticlesDropdownComponent, [i8.HelpCenterService, i9.HcUrls, i10.Router, i11.DomSanitizer], { category: [0, "category"], placeholder: [1, "placeholder"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header"; var currVal_1 = true; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.category; var currVal_4 = _co.settings.get("hc.home.search-placeholder"); _ck(_v, 12, 0, currVal_3, currVal_4); }, null); }
export function View_HcCompactHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hc-compact-header", [["id", "hc-compact-header"]], null, null, null, View_HcCompactHeaderComponent_0, RenderType_HcCompactHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i12.HcCompactHeaderComponent, [i4.Settings], null, null)], null, null); }
var HcCompactHeaderComponentNgFactory = i1.ɵccf("hc-compact-header", i12.HcCompactHeaderComponent, View_HcCompactHeaderComponent_Host_0, { category: "category" }, {}, ["*"]);
export { HcCompactHeaderComponentNgFactory as HcCompactHeaderComponentNgFactory };
