/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-container.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/portal";
import * as i3 from "@angular/common";
import * as i4 from "./preview-container.component";
import * as i5 from "../preview-files.service";
var styles_PreviewContainerComponent = [i0.styles];
var RenderType_PreviewContainerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PreviewContainerComponent, data: {} });
export { RenderType_PreviewContainerComponent as RenderType_PreviewContainerComponent };
function View_PreviewContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PreviewContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("attached" === en)) {
        var pd_0 = (_co.test($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PreviewContainerComponent_2)), i1.ɵdid(3, 212992, null, 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, { attached: "attached" }), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PreviewContainerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_PreviewContainerComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.previewFiles.getPortal())); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PreviewContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "preview-container", [], null, null, null, View_PreviewContainerComponent_0, RenderType_PreviewContainerComponent)), i1.ɵdid(1, 704512, null, 0, i4.PreviewContainerComponent, [i5.PreviewFilesService], null, null)], null, null); }
var PreviewContainerComponentNgFactory = i1.ɵccf("preview-container", i4.PreviewContainerComponent, View_PreviewContainerComponent_Host_0, { files: "files" }, {}, []);
export { PreviewContainerComponentNgFactory as PreviewContainerComponentNgFactory };
