<ng-container *ngIf="navData && navData.children">
    <div class="sidenav-content scroll-container" customScrollbar="lite">
        <ng-container *ngFor="let category of navData.children">
            <h3><a [routerLink]="urls.getCategoryLink(category)" [class.active]="routeIsActive('category', category.id)">{{category.name}}</a></h3>
            <ul>
                <li *ngFor="let article of category.articles">
                    <a [routerLink]="urls.getArticleLink(article)" [class.active]="routeIsActive('article', article.id)">{{article.title}}</a>
                </li>
            </ul>
        </ng-container>
    </div>

    <button class="mobile-toggle no-style" (click)="toggleSidenav()"><mat-icon svgIcon="menu"></mat-icon></button>
</ng-container>