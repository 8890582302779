import { Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { CommandTypes } from './commands/command-types';
import { createSelectorBox } from './utils/create-selector-box';
import { selectNode } from './utils/select-node';
import { deselectNode } from './utils/deselect-node';
import { filter } from 'rxjs/operators';
import { Toast } from '../../core/ui/toast.service';
import { createCustomStyleNode } from './utils/create-custom-style-node';
import { createCustomCodeNode } from './utils/create-custom-code-node';
import { extractHostname } from '../../core/utils/extractHostname';
import * as i0 from "@angular/core";
import * as i1 from "../../core/ui/toast.service";
import * as i2 from "@angular/router";
import * as i3 from "../../core/config/settings.service";
var AppearanceListenerService = /** @class */ (function () {
    function AppearanceListenerService(toast, router, settings) {
        this.toast = toast;
        this.router = router;
        this.settings = settings;
        this.active = false;
        this.dom = {};
    }
    AppearanceListenerService.prototype.init = function () {
        if (!this.isPreviewMode())
            return;
        this.listenForMessages();
        this.blockNotAllowedRoutes();
        this.createDomNodes();
    };
    AppearanceListenerService.prototype.isPreviewMode = function () {
        return this.active = window.location.search.indexOf('preview=' + this.settings.csrfToken) > -1;
    };
    AppearanceListenerService.prototype.listenForMessages = function () {
        var _this = this;
        window.addEventListener('message', function (e) {
            if (_this.isAppearanceEvent(e) && _this.eventIsTrusted(e)) {
                _this.handleCommand(e.data);
            }
        });
    };
    AppearanceListenerService.prototype.handleCommand = function (command) {
        // TODO: fix command data typings (remove "any")
        switch (command.type) {
            case (CommandTypes.Navigate):
                return this.router.navigate([command.route]);
            case (CommandTypes.SetConfig):
                return this.settings.set(command.key, command.value, true);
            case (CommandTypes.Select):
                return selectNode(command.selector, command.index, this.dom.selectBox);
            case (CommandTypes.Deselect):
                return deselectNode(this.dom.selectBox);
            case (CommandTypes.SetColors):
                return this.dom.colors.innerHTML = command.css;
            case (CommandTypes.SetCustomCss):
                return createCustomCodeNode('css', this.settings.getBaseUrl(true));
            case (CommandTypes.SetCustomJs):
                return createCustomCodeNode('js', this.settings.getBaseUrl(true));
        }
    };
    AppearanceListenerService.prototype.eventIsTrusted = function (e) {
        return extractHostname(e.origin) === window.location.hostname;
    };
    AppearanceListenerService.prototype.isAppearanceEvent = function (e) {
        var data = e.data;
        return data && (data.type in CommandTypes);
    };
    AppearanceListenerService.prototype.createDomNodes = function () {
        this.dom.selectBox = createSelectorBox();
        this.dom.colors = createCustomStyleNode();
    };
    AppearanceListenerService.prototype.blockNotAllowedRoutes = function () {
        var _this = this;
        var config = this.settings.get('vebto.admin.appearance');
        this.router.events
            .pipe(filter(function (e) { return e.toString().indexOf('NavigationStart') === 0; }))
            .subscribe(function (e) {
            if (e.url === '/')
                return;
            // route exists in config, bail
            var routes = config.navigationRoutes;
            if (routes.find(function (route) { return e.url.indexOf(route) > -1; }))
                return;
            // prevent navigation to routes not specified in config
            var current = _this.router.url.split('?')[0];
            _this.router.navigate([current], { queryParamsHandling: 'preserve' });
            setTimeout(function () { return _this.toast.open('That page is not supported by the editor.'); });
        });
    };
    AppearanceListenerService.ngInjectableDef = i0.defineInjectable({ factory: function AppearanceListenerService_Factory() { return new AppearanceListenerService(i0.inject(i1.Toast), i0.inject(i2.Router), i0.inject(i3.Settings)); }, token: AppearanceListenerService, providedIn: "root" });
    return AppearanceListenerService;
}());
export { AppearanceListenerService };
