import {Injectable} from '@angular/core';
import {Router, Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {HelpCenterService} from '../../shared/help-center.service';
import {Article} from '../../../shared/models/Article';
import {Settings} from '../../../../common/core/config/settings.service';

@Injectable()
export class HcSearchPageResolve implements Resolve<{data: Article[]}> {

    constructor(
        private helpCenter: HelpCenterService,
        private router: Router,
        private settings: Settings,
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<{data: Article[]}> {
        const query     = route.params['query'],
            limit     = this.settings.get('hc.search_page.limit', 20),
            bodyLimit = this.settings.get('hc.search_page.body_limit', 300);

        return this.helpCenter.findArticles(query, {limit: limit, body_limit: bodyLimit}).toPromise().then(response => {
            return response.data;
        }, () => {
            this.router.navigate(['/help-center']);
            return false;
        }) as any;
    }
}
