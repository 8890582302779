<material-navbar menuPosition="header" [container]="true" [showAuthButtons]="true"></material-navbar>

<div class="page-header">
    <div class="container">
        <ng-content></ng-content>
        <div class="search-bar">
            <suggested-articles-dropdown [placeholder]="settings.get('hc.home.search-placeholder')" [category]="category"></suggested-articles-dropdown>
        </div>
    </div>
</div>
