import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HcUrls} from '../../shared/hc-urls.service';
import {Article} from '../../../shared/models/Article';
import {Settings} from '../../../../common/core/config/settings.service';
import {HcTitleService} from '../../../shared/hc-title.service';

@Component({
    selector: 'hc-search-page',
    templateUrl: './hc-search-page.component.html',
    styleUrls: ['./hc-search-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HcSearchPageComponent implements OnInit {

    /**
     * Query search should be performed on.
     */
    public query: string;

    /**
     * How much articles to show per page.
     */
    public perPage = 20;

    /**
     * Search results.
     */
    public results: Article[];

    /**
     * HcSearchPageComponent Constructor.
     */
    constructor(
        private route: ActivatedRoute,
        private settings: Settings,
        public urls: HcUrls,
        private title: HcTitleService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => this.query = params['query']);
        this.route.data.subscribe(data => this.results = data['results']);
        this.perPage = this.settings.get('hc.search_page.limit', 20);
        this.title.setSearchTitle(this.query);
    }
}
