var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TitleService } from '../../common/core/services/title.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../../common/core/translations/translations.service";
import * as i4 from "../../common/core/config/settings.service";
var HcTitleService = /** @class */ (function (_super) {
    __extends(HcTitleService, _super);
    function HcTitleService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.separator = ' - ';
        return _this;
    }
    /**
     * Get help center home title.
     */
    HcTitleService.prototype.getHomeTitle = function () {
        return this.i18n.t('Help Center') + this.separator + this.settings.get('branding.site_name');
    };
    /**
     * Set help center home title.
     */
    HcTitleService.prototype.setHomeTitle = function () {
        var title = this.i18n.t('Help Center') + this.separator + this.settings.get('branding.site_name');
        this.title.setTitle(title);
    };
    /**
     * Set help center article title.
     */
    HcTitleService.prototype.setArticleTitle = function (article) {
        this.title.setTitle(article.title + this.separator + this.getHomeTitle());
    };
    /**
     * Set help center category title.
     */
    HcTitleService.prototype.setCategoryTitle = function (category) {
        this.title.setTitle(category.name + this.separator + this.getHomeTitle());
    };
    /**
     * Set help center search title.
     */
    HcTitleService.prototype.setSearchTitle = function (query) {
        this.title.setTitle(this.i18n.t('Search Results') + this.separator + this.getHomeTitle());
    };
    HcTitleService.ngInjectableDef = i0.defineInjectable({ factory: function HcTitleService_Factory() { return new HcTitleService(i0.inject(i1.Router), i0.inject(i2.Title), i0.inject(i3.Translations), i0.inject(i4.Settings)); }, token: HcTitleService, providedIn: "root" });
    return HcTitleService;
}(TitleService));
export { HcTitleService };
