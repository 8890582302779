import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HcUrls} from '../shared/hc-urls.service';
import {Category} from '../../shared/models/Category';
import {UrlAwarePaginator} from '../../../common/admin/pagination/url-aware-paginator.service';
import {CurrentUser} from '../../../common/auth/current-user';
import {Settings} from '../../../common/core/config/settings.service';
import {HcTitleService} from '../../shared/hc-title.service';

@Component({
    selector: 'help-center',
    templateUrl: './help-center.component.html',
    styleUrls: ['./help-center.component.scss'],
    providers: [UrlAwarePaginator],
    encapsulation: ViewEncapsulation.None,
})
export class HelpCenterComponent implements OnInit {
    /**
     * All available categories.
     */
    public categories: Category[] = [];

    /**
     * CategoriesComponent Constructor.
     */
    constructor(
        public currentUser: CurrentUser,
        public urls: HcUrls,
        private route: ActivatedRoute,
        private title: HcTitleService,
        private settings: Settings
    ) {}

    ngOnInit() {
        this.title.setHomeTitle();
        this.categories = this.route.snapshot.data['categories'];
    }

    /**
     * Check if specified category should be shown on hc homepage.
     */
    public shouldHideCategory(category: Category): boolean {
        if (category.hidden || ! category.articles) return true;

        return this.settings.get('hc_home.hide_small_categories') &&
            category.articles.length < 2;
    }
}
